.diagnosticoMo {
  display: flex;
  flex-direction: column;
}

.diagnosticoRequisiciones {
  width: 100%;
}

/* @media (max-width: 1000px) {
  .diagnosticoRequisiciones {
    width: 100%;
  }
} */

.margenMo {
background-color: #DCDCDC;
}

.margenTablaDiagnosticoRequisiciones {
  background-color: #DCDCDC;
}
@media (max-width: 600px) {
  .margenTablaDiagnosticoRequisiciones {
    display: flex;
  }
}

.botonGuardarDiagnostico {
  background-color: #DCDCDC;
  margin-left: 15%;
  width: 100%;
}
@media (max-width: 600px) {
  .botonGuardarDiagnostico {
    margin-left: 15%;
    width: 100%;
  }
}

.margenTabl2DiagnosticosRequisiciones {
  background-color: #DCDCDC;
}

@media (max-width: 600px) {
  .margenTabl2DiagnosticosRequisiciones {
    display: flex;
  }
}

@media (max-width: 900px) {
  .margenTabl2DiagnosticosRequisiciones {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .margenMo {
    margin-top: 10%;
    display: flex;
  }
}

@media (max-width: 600px) {
  .diagnosticoMo {
    flex-direction: column;
    margin-left: 6%;
  }
}

@media (min-width: 900px) {
  .diagnosticoMo {
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 100%;

  }
}

